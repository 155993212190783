enum DpkType {

    OpenPosition = "openPosition",
    openposition = "openposition",
    OpenDeposit = "opendeposit",
    Deposit = "deposit",
    OpenUploadDocument = "openuploaddocument",
    UserFeedback = "feedback",
    StartChat = "startchat",
    RegisterActivity = "registeractivity",
    Signal = "signal",
    Position = "position",
    ClosedPosition = "closedposition",
    Order = "order",
    Promotions = "promotions",
    ChangePassword = "changepassword",
    Portfolio = "portfolio",
    Academy = "academy",
    NativeReview = "NativeReview",
    GivePromo = "givepromo",
    SignTransactions = "signTransactions",
    PushPermission = "ntf-permission",
    Confetti = "confetti",
}

namespace DpkType {

    export function deserialize(v: string): DpkType {
        if (v) {
            if (v.indexOf("dpk:openPosition") === 0) {
                return DpkType.OpenPosition;
            } else if (v.indexOf("dpk:openposition") === 0) {
                return DpkType.openposition;
            } else if (v.indexOf("dpk:opendeposit") === 0) {
                return DpkType.OpenDeposit;
            } else if (v.indexOf("dpk:deposit") === 0) {
                return DpkType.Deposit;
            } else if (v.indexOf("dpk:openuploaddocument") === 0) {
                return DpkType.OpenUploadDocument;
            } else if (v.indexOf("dpk:feedback") === 0) {
                return DpkType.UserFeedback;
            } else if (v.indexOf("dpk:startchat") === 0) {
                return DpkType.StartChat;
            } else if (v.indexOf("dpk:registeractivity") === 0) {
                return DpkType.RegisterActivity;
            } else if (v.indexOf("dpk:signal") === 0) {
                return DpkType.Signal;
            } else if (v.indexOf("dpk:position") === 0) {
                return DpkType.Position;
            } else if (v.indexOf("dpk:closedposition") === 0) {
                return DpkType.ClosedPosition;
            } else if (v.indexOf("dpk:order") === 0) {
                return DpkType.Order;
            } else if (v.indexOf("dpk:promotions") === 0) {
                return DpkType.Promotions;
            } else if (v.indexOf("dpk:changepassword") === 0) {
                return DpkType.ChangePassword;
            } else if (v.indexOf("dpk:portfolio") === 0) {
                return DpkType.Portfolio;
            } else if (v.indexOf("dpk:academy") === 0) {
                return DpkType.Academy;
            } else if (v.indexOf("dpk:NativeReview") === 0) {
                return DpkType.NativeReview;
            } else if (v.indexOf("dpk:givepromo") === 0) {
                return DpkType.GivePromo;
            } else if (v.indexOf("dpk:signTransactions") === 0) {
                return DpkType.SignTransactions;
            } else if (v.indexOf("dpk:confetti") === 0) {
                return DpkType.Confetti;
            }
        }
        return null;
    }
}

export {DpkType};
