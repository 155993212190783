class Confetti {

    public static Run = async (): Promise<void> => {
        const jsConfetti: any = await import('js-confetti');
        const confetti = new jsConfetti.default();
        await confetti.addConfetti({
            confettiRadius: 6,
            confettiNumber: 500,
        });
    }
}

export {
    Confetti
};
