import {Engine} from "platform/engine/Engine";
import {
    DoChangePasswordPayload,
    DoCreateRelatedLeadPayload,
    DoLoginToAccountPayload,
    DoLogout,
    DoLogoutPayload,
    DoSwitchAccountPayload,
    GetSecuredDataPayload,
    GetUnmaskingSecuredFieldVerificationCodePayload,
    HandleLoginResponse,
    HandleLoginResponsePayload,
    OnAccountChanged,
    OnLoggedOut,
    SetAuthStatus,
    SetLoginAccountAction,
    SetRelatedLeadUsername,
    SetVerificationCodeError,
    SetVerificationIsLimitReached,
    SetVerificationMethod,
    SetVerificationMethods,
} from "core/redux/auth/AuthReduxActions";
import {
    SetClientPreference,
    SetEMTEnabled,
    SetEnabledSkipEducationTradingGroup,
    SetPreferencesFetched
} from "core/redux/settings/SettingsReduxActions";
import Platform from "platform/Platform";
import {AuthState} from "core/state/AuthState";
import {ServiceType} from "enum/ServiceType";
import Utils from "platform/util/Utils";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {StoreState} from "core/redux/StoreState";
import {LoginAccountInfo} from "protocol/auth/LoginAccountInfo";
import {LoginToAccountRequest} from "protocol/auth/LoginToAccountRequest";
import {LoginToAccountResponse} from "protocol/auth/LoginToAccountResponse";
import {HttpReject} from "platform/network/http/Http";
import {Xhr} from "platform/network/xhr/Xhr";
import {ServerType} from "platform/enum/ServerType";
import {CloseAllPopups, ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType, PopupType} from "platform/redux/popups/PopupsReduxState";
import {StorageKey} from "enum/StorageKey";
import {LogoutRequest} from "protocol/auth/LogoutRequest";
import {XhrUtil} from "core/util/XhrUtil";
import {AccountState} from "core/state/AccountState";
import {BadRequestResponse} from "protocol/BadRequestResponse";
import {HideLoader, InitChat, SetLoader, SetTheme, SetUrls} from "platform/redux/core/CoreActions";
import {DoRegisterUserActivity, SetAccountInfo} from "core/redux/account/AccountReduxActions";
import {LoginConfigurationRequest} from "protocol/config/LoginConfigurationRequest";
import {Configuration} from "core/configuration/Configuration";
import {
    AvailableLanguage,
    LoginConfigurationResponse,
    LoginDynamicConfiguration
} from "protocol/config/LoginConfigurationResponse";
import SessionInactivity from "core/util/SessionInactivity";
import {LangCode} from "platform/enum/LangCode";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {ChangePasswordRequest} from "protocol/account/ChangePasswordRequest";
import {ChangePasswordResponse} from "protocol/account/ChangePasswordResponse";
import {ClientPreference} from "protocol/account/ClientPreference";
import {SetSupportedLanguages} from "platform/redux/translation/TranslationActions";
import {LSKey} from "platform/storage/Storage";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {PreferencesManager} from "core/engine/PreferencesManager";
import {LoaderType} from "platform/enum/LoaderType";
import {Preference} from "core/util/Preference";
import {SetDynamicConfiguration, SetTradingModal, ToggleAccountDetailsSwiper} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {UrlType} from "platform/enum/UrlType";
import {Account, GetAfterLoginConfigurationResponse} from "protocol/account/GetAfterLoginConfigurationResponse";
import WebUtil from "platform/util/WebUtil";
import {EnvType} from "platform/enum/EnvType";
import {GetAccountStateResponse} from "protocol/account/GetAccountStateResponse";
import {RouteType} from "core/router/Routes";
import {LoginResponse, ResponseAccount} from "protocol/auth/LoginResponse";
import {ThemeType} from "platform/enum/ThemeType";
import InboxEngine from "core/engine/InboxEngine";
import {UpdateSignalsSettings} from "core/redux/deal/SignalsReduxActions";
import {SetLoginRoute} from "core/redux/login/LoginReduxActions";
import {Environment} from "platform/enum/Environment";
import {SetNotificationTab} from "core/redux/inbox/InboxReduxActions";
import {NotificationTab} from "enum/NotificationTab";
import {BIUtil} from "core/util/BIUtil";
import {BILoginType} from "enum/BILoginType";
import {BISessionEndType} from "enum/BISessionEndType";
import {TradeUtil} from "core/util/TradeUtil";
import {CreateRelatedLeadResponse} from "protocol/auth/CreateRelatedLeadResponse";
import {TradingAccountAction} from "enum/TradingAccountAction";
import {
    DynamicConfiguration,
    SupportedBrowser,
    SymbolAlertConfigs
} from "platform/protocol/common/DynamicConfiguration";
import {ApplicationActionType} from "enum/ApplicationActionType";
import {AutoLogin} from "core/engine/AutoLogin";
import {AppState} from "core/state/AppState";
import {CustomerIOWeb} from "platform/analytics/CustomerIOWeb";
import {UserActivityType} from "enum/UserActivityType";
import {GetAfterLoginConfigurationRequest} from "protocol/account/GetAfterLoginConfigurationRequest";
import {GetUnmaskingSecuredFieldVerificationResponse} from "protocol/auth/GetUnmaskingSecuredFieldVerificationResponse";
import {GetSecuredDataResponse} from "protocol/auth/GetSecuredDataResponse";
import {GetUnmaskingSecuredFieldVerificationRequest} from "protocol/auth/GetUnmaskingSecuredFieldVerificationRequest";
import {GetSecuredDataRequest} from "protocol/auth/GetSecuredDataRequest";
import {VerificationMethodType} from "enum/VerificationMethodType";
import {MaskedFieldType} from "protocol/auth/MaskedFieldType";
import {ConfigUtil} from "core/util/ConfigUtil";
import {AppCuesManager} from "core/appCues/IAppCues";

const Token: string = WebUtil.urlParam("token") || WebUtil.urlParam("tokenId");
const AppAction: ApplicationActionType = WebUtil.urlParam("action") as ApplicationActionType;

export default class AuthEngine extends Engine {

    private static _instance: AuthEngine;
    private _initialized: boolean;
    private _ping: any;

    public static instance(): AuthEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        this._initialized = false;
        SessionInactivity.setThreshold(Platform.config<Configuration>().activeTraderIdleTimeBeforeLogoff);
        this.startPing();
    }

    public startPing = (): void => {
        if (Utils.isNull(this._ping)) {
            this._logger.debug("Start ping");
            this.fetchConfig();
            this._ping = setInterval(this.fetchConfig, 1000);
        }
    }

    private stopPing = (): void => {
        if (Utils.isNotNull(this._ping)) {
            this._logger.debug("Stop ping");
            clearInterval(this._ping);
            this._ping = null;
        }
    }

    private fetchConfig = (): void => {
        const request: LoginConfigurationRequest = {
            BrandId: Platform.config<Configuration>().brandId,
            LanguageCode: LanguageUtil.languageCode()
        };
        this.sendToLogin(request, "LoginConfiguration")
            .then(async (response: LoginConfigurationResponse) => {
                this._logger.debug("Received Login configuration. Success: " + response.SuccessStatus);
                if (response.SuccessStatus) {
                    this.stopPing();
                    Platform.dispatch(SetAuthStatus({
                        authStatus: {connected: true}
                    }));
                    const loginConfiguration: LoginDynamicConfiguration = response.DynamicConfiguration || {};
                    let AlertConfigs: SymbolAlertConfigs = {};
                    let SupportedBrowsers: {[key: string]: SupportedBrowser} = {};
                    let CustomerIoBrands: number[] = [];
                    if (loginConfiguration.SymbolAlertConfigs) {
                        try {
                            AlertConfigs = JSON.parse(loginConfiguration.SymbolAlertConfigs);
                        } catch (e) {}
                    }
                    if (loginConfiguration.SupportedBrowsers) {
                        try {
                            SupportedBrowsers = JSON.parse(loginConfiguration.SupportedBrowsers);
                        } catch (e) {
                            this._logger.warn(`Failed parse supported browsers: ${loginConfiguration.SupportedBrowsers}`)
                        }
                    }
                    if (loginConfiguration.CustomerIoBrands) {
                        try {
                            CustomerIoBrands = JSON.parse(loginConfiguration.CustomerIoBrands);
                        } catch (e) {}
                    }
                    const dynamicConfiguration: DynamicConfiguration = {
                        CustomerIoSiteId: loginConfiguration.CustomerIoSiteId,
                        CustomerIoApiKey: loginConfiguration.CustomerIoApiKey,
                        CustomerIoOrganizationId: loginConfiguration.CustomerIoOrganizationId,
                        CustomerIoRegion: loginConfiguration.CustomerIoRegion,
                        CustomerIoBrands,
                        SupportedBrowsers,
                        SymbolAlertConfigs: AlertConfigs,
                        DisableRealizedPLDetails: Utils.parseBoolean(loginConfiguration.DisableRealizedPLDetails),
                        ShowAccountSummary: Utils.parseBoolean(loginConfiguration.ShowAccountSummary),
                        AlertsFullSupport: Utils.parseBoolean(loginConfiguration.AlertsFullSupport),
                        DepositOnZeroBalance: Utils.parseBoolean(loginConfiguration?.DepositOnZeroBalance),
                        DepositOnInsufficientFunds: Utils.parseBoolean(loginConfiguration?.DepositOnInsufficientFunds),
                        SignalsCustomerSupportInActivity: Utils.parseNumber(loginConfiguration?.SignalsCustomerSupportInActivity),
                        MyTradesShortView: Utils.parseBoolean(loginConfiguration?.MyTradesShortView),
                    };
                    this._logger.debug("ShowAccountSummary: " + loginConfiguration.ShowAccountSummary);
                    Platform.dispatch(SetDynamicConfiguration({
                        configuration: dynamicConfiguration
                    }));
                    Platform.storage().getItem(LSKey.Theme).then((theme: string) => {
                        if (Utils.isEmpty(theme) && Utils.isNotEmpty(loginConfiguration.DefaultPlatformTheme)) {
                            const themeType: ThemeType = ThemeType.deserialize(loginConfiguration.DefaultPlatformTheme);
                            Platform.dispatch(SetTheme({
                                themeType
                            }));
                            Platform.dispatch(SetClientPreference({
                                key: LSKey.Theme, preference: Preference.Of(themeType)
                            }));
                        }
                    });
                    if (!this._initialized) {
                        this._initialized = true;
                        Platform.dispatch(SetUrls({
                            urls: [
                                {type: UrlType.NewLogin, url: ConfigUtil.UseAddressBarDomain(decodeURIComponent(response.NewLoginUrl))}
                            ]
                        }));
                        if (Platform.environment().type() === EnvType.Web) {
                            await this.doGetAfterLoginConfiguration();
                            const appState: AppState = Platform.state(ServiceType.App);
                            if (Utils.isArrayNotEmpty(response.AvailableLanguageConfigurations)) {
                                const languages: LangCode[] = [];
                                response.AvailableLanguageConfigurations.forEach((langConfig: AvailableLanguage) => {
                                    const langCode: LangCode = LangCode[langConfig.Code.toUpperCase()];
                                    if (Utils.isNotNull(langCode) && Platform.config<Configuration>().supportedLanguages.indexOf(langCode) > -1) {
                                        languages.push(langCode);
                                        appState.addLanguage(langCode, langConfig);
                                    }
                                });
                                Platform.dispatch(SetSupportedLanguages({languages}));
                            }
                            if (response.ChatUrl && WebUtil.isUrl(response.ChatUrl)) {
                                Platform.dispatch(InitChat({scriptUrl: response.ChatUrl}));
                            } else {
                                this._logger.warn("Z-chat url not configured");
                            }
                        } else {
                            await this.tryNativeLogin();
                        }
                    } else {
                        this._logger.debug("Already initialized");
                    }
                } else {
                    this._logger.debug("Failed fetch config: " + response.LocalizedErrorMessage);
                }
            })
            .catch((e) => {
                this._logger.warn("Failed fetch configuration");
            });
    }

    public async doGetAfterLoginConfiguration(): Promise<void> {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const authState: AuthState = Platform.state(ServiceType.Auth);
        const prevToken = await Platform.storage().getItem(LSKey.Token);
        let token: string = Token;
        if (token) {
            Platform.storage().setItem(LSKey.Token, Token);
        } else {
            token = prevToken;
        }
        if (Utils.isNotEmpty(token)) {
            authState.token = token;
            const answer: [HttpReject, GetAfterLoginConfigurationResponse] = await Utils.to(this.sendToLoginSecure({}, "GetAfterLoginConfigurationForPlatform"));
            if (answer[0]) {
                let response: BadRequestResponse;
                try {
                    response = JSON.parse(answer[0].response);
                } catch (e) {
                    this._logger.warn("Can't parse get account state failed response");
                }
                if (Utils.isNull(response) || response.IsSecurityException) {
                    this._logger.debug("Failed fetch after Login details.");
                    this.clear();
                }
            } else {
                const {
                    UserId,
                    UserName,
                    IsRealAccount,
                    Accounts,
                    CesLeadToken,
                    PlexopLeadId,
                    PlexopLeadHash,
                    EnableExposureManagementTool,
                    CanSkipEducation,
                    VerificationCodeChannels,
                    MaskedUserProfileFields
                } = answer[1];
                window.GlobalVars.Lead.CesLeadToken = CesLeadToken;
                window.GlobalVars.Lead.PlexopLeadId = PlexopLeadId;
                window.GlobalVars.Lead.PlexopLeadHash = PlexopLeadHash;
                window.GlobalVars.Ces.Connected = async () => {
                    const IsShouldReportConnected: boolean = Utils.isEmpty(prevToken) || prevToken !== token;
                    this._logger.debug(`On Ces connected. Should report to Profit: ${IsShouldReportConnected}`);
                    if (IsShouldReportConnected) {
                        this._logger.debug("Notify Ces connected.");
                        Platform.dispatch(DoRegisterUserActivity({userActivity: UserActivityType.CesConnected}));
                    }
                };
                accountState.username = UserName;
                accountState.userId = UserId;
                if (Utils.isArrayNotEmpty(Accounts)) {
                    Accounts.forEach((ac: Account) => {
                        authState.addAccount({
                            ProfitTradingGroupId: ac.H,
                            BaseAssetId: ac.A,
                            PlatformType: ac.E,
                            EnvironmentType: ac.B,
                            AccountType: ac.I,
                            RelatedAccountType: ac.J,
                            Id: ac.C,
                            RelatedAccountId: ac.K,
                            BaseAssetName: ac.BaseAssetName
                        });
                    });
                }
                const realAccount: LoginAccountInfo = authState.getAccount(NXEnvironmentType.Live);
                const demoAccount: LoginAccountInfo = authState.getAccount(NXEnvironmentType.Demo);
                const hasRealAccount: boolean = Utils.isNotNull(realAccount);
                const hasDemoAccount: boolean = Utils.isNotNull(demoAccount);
                const sAccountType: string = await Platform.storage().getItem(StorageKey.AccountType);
                const prevAccountType: NXEnvironmentType = parseInt(sAccountType, 10);
                let accountType: NXEnvironmentType;
                if (prevAccountType) {
                    if (prevAccountType === NXEnvironmentType.Live && hasRealAccount) {
                        accountType = NXEnvironmentType.Live;
                    }
                    if (prevAccountType === NXEnvironmentType.Demo && hasDemoAccount) {
                        accountType = NXEnvironmentType.Demo;
                    }
                }
                if (!accountType) {
                    accountType = hasRealAccount ? NXEnvironmentType.Live : NXEnvironmentType.Demo;
                }
                const account: LoginAccountInfo = authState.getAccount(accountType);
                this._logger.info("After login response. Use account: " + accountType + " Has demo: " + hasDemoAccount + " Has real: " + hasRealAccount);
                this._logger.info("Login account type: " + account.AccountType);
                accountState.accountType = accountType;
                accountState.accountId = account.Id;
                Platform.dispatch(SetAuthStatus({
                    authStatus: {
                        authenticated: true,
                        hasRealAccount,
                        hasDemoAccount,
                        hasRelatedAccount: Utils.greaterThen0(account.RelatedAccountId),
                        accountType,
                        relatedAccountType: account.RelatedAccountType,
                        loginAccountType: account.AccountType,
                        loginAccountAction: TradeUtil.tradingAccountAction(account),
                        verificationMethods: VerificationCodeChannels,
                        MaskedUserProfileFields
                    }
                }));
                Platform.dispatch(SetAccountInfo({
                    accountInfo: {
                        username: UserName,
                        userId: UserId,
                        Currency: account.BaseAssetName
                    }
                }));
                SessionInactivity.start();
                this.fetchPreferences(account).catch(() => {});
                Platform.dispatch(SetEnabledSkipEducationTradingGroup({value: Utils.isNotNull(CanSkipEducation) ? CanSkipEducation : true}));
                Platform.dispatch(SetEMTEnabled({value: EnableExposureManagementTool}));
                BIUtil.SessionStart(
                    UserId,
                    IsRealAccount,
                    Token ? BILoginType.Regular : BILoginType.Remembered,
                    WebUtil.isMobile() ? "Web Mobile" : "Web Desktop"
                );
                AppCuesManager.SetIdentify({
                    userId: UserId,
                    langCode: LanguageUtil.languageCode()
                });
                const {CustomerIoBrands, CustomerIoOrganizationId, CustomerIoSiteId} = Platform.reduxState<StoreState>().app.dynamicConfiguration;
                if (CustomerIoBrands?.indexOf(Platform.config().brandId) >= 0 && CustomerIoOrganizationId && CustomerIoSiteId) {
                    CustomerIOWeb.identify(CustomerIoOrganizationId, CustomerIoSiteId, {
                        id: UserId,
                        email: UserName
                    });
                }
                if (AppAction === ApplicationActionType.InvestmentWelcomeModal) {
                    Platform.dispatch(SetTradingModal({
                        tradingModalType: TradingModalType.WelcomeInvestAccount,
                        info: {
                            visible: true,
                        },
                    }));
                }
            }
        } else {
            this._logger.debug("Token absent");
            this.clear();
        }
    }

    public async tryNativeLogin(): Promise<void> {
        const token: string = await Platform.storage().getItem(LSKey.Token);
        this._logger.debug("Try Native Login. Has token: " + Utils.isNotEmpty(token));
        if (Utils.isNotEmpty(token)) {
            const sAccountType: string = await Platform.storage().getItem(StorageKey.AccountType);
            const accountType: NXEnvironmentType = parseInt(sAccountType, 10);
            const accountState: AccountState = Platform.state(ServiceType.Account);
            const authState: AuthState = Platform.state(ServiceType.Auth);
            accountState.accountType = accountType;
            authState.token = token;
            this._logger.debug("Check native login for account: " + accountType + " Has token: " + Utils.isNotEmpty(token));
            XhrUtil.sendToAccountService({}, "GetAccountState").then(async (response: GetAccountStateResponse) => {
                this._logger.debug("Native Logged in with account type: " + accountType);
                const {brandId} = Platform.config();
                const username: string = await Platform.storage().getItem(StorageKey.UsernameKey(brandId, Environment.environment()));
                const sUserId: string = await Platform.storage().getItem(StorageKey.UserId);
                accountState.accountId = response.AccountId;
                accountState.username = username;
                accountState.userId = parseInt(sUserId, 10);
                await authState.deserializeAccounts();
                const account: LoginAccountInfo = authState.getAccount(accountType);
                Platform.dispatch(SetAuthStatus({
                    authStatus: {
                        authenticated: true,
                        accountType,
                        hasRealAccount: Utils.isNotNull(authState.getAccount(NXEnvironmentType.Live)),
                        hasDemoAccount: Utils.isNotNull(authState.getAccount(NXEnvironmentType.Demo)),
                        hasRelatedAccount: Utils.greaterThen0(account.RelatedAccountId),
                        relatedAccountType: account.RelatedAccountType,
                        loginAccountType: account.AccountType,
                        loginAccountAction: TradeUtil.tradingAccountAction(account)
                    }
                }));
                Platform.dispatch(SetAccountInfo({
                    accountInfo: {
                        username,
                        userId: parseInt(sUserId, 10),
                        Currency: account.BaseAssetName
                    }
                }));
                SessionInactivity.start();
                this.fetchPreferences(account).catch(() => {});
                this.nativeGetAfterLoginConfiguration().catch(() => {});
                const sRealUser: string = await Platform.storage().getItem(StorageKey.IsRealUser);
                const realUser: boolean = Utils.nullTo(Utils.parseBoolean(sRealUser), true);
                BIUtil.SessionStart(accountState.userId, realUser, BILoginType.Remembered, "Mobile");
            }).catch(async (httpReject: HttpReject) => {
                let response: BadRequestResponse;
                try {
                    response = JSON.parse(httpReject.response);
                } catch (e) {
                    this._logger.warn("Can't parse get account state failed response");
                }
                if (Utils.isNull(response) || response.IsSecurityException) {
                    this._logger.debug("Failed Native auto login.");
                    this.clear();
                }
            });
        } else {
            if (AutoLogin.payload) {
                Platform.dispatch(HandleLoginResponse(AutoLogin.payload));
                AutoLogin.payload = null;
                Platform.dispatch(SetAuthStatus({
                    authStatus: {
                        autoLoginBrand: null
                    }
                }));
            } else {
                this._logger.debug("Token absent in LS");
                this.clear();
            }
        }
    }

    private async nativeGetAfterLoginConfiguration(): Promise<void> {
        this._logger.debug("Native fetch AfterLoginConfiguration");
        const request: GetAfterLoginConfigurationRequest = {
            PlatformType: AppState.instance().nativeMobilePlatformType
        };
        const answer: [HttpReject, GetAfterLoginConfigurationResponse] = await Utils.to(this.sendToLoginSecure(request, "GetAfterLoginConfigurationForPlatform"));
        if (answer[1]) {
            const {LabelId, UserId, UserName, VerificationCodeChannels, MaskedUserProfileFields} = answer[1];
            const accountState: AccountState = Platform.state(ServiceType.Account);
            accountState.username = UserName;
            accountState.userId = UserId;
            Platform.dispatch(SetAccountInfo({
                accountInfo: {
                    LabelId,
                    username: UserName,
                    userId: UserId,
                }
            }));
            const {PlexopLeadId, PlexopLeadHash, CesLeadToken, EnableExposureManagementTool, IsRateApplicationEnabled, CanSkipEducation} = answer[1];
            this._logger.debug("Native save PleXopLeadId: " + PlexopLeadId);
            Platform.dispatch(SetAuthStatus({
                authStatus: {
                    IsRateApplicationEnabled,
                    pleXopUser: {
                        pleXopLeadId: PlexopLeadId,
                        pleXopLeadHash: PlexopLeadHash,
                        cesLeadToken: CesLeadToken
                    },
                    verificationMethods: VerificationCodeChannels,
                    MaskedUserProfileFields
                }
            }));
            Platform.dispatch(SetEnabledSkipEducationTradingGroup({value: Utils.isNotNull(CanSkipEducation) ? CanSkipEducation : true}));
            Platform.dispatch(SetEMTEnabled({value: EnableExposureManagementTool}));
        } else {
            this._logger.warn("Failed native fetch AfterLoginConfiguration");
        }
    }

    private clear = (): void => {
        this._logger.debug("Clear");
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const authState: AuthState = Platform.state(ServiceType.Auth);
        accountState.clear();
        authState.clear();
        Platform.storage().removeItem(LSKey.Token);
        Platform.storage().removeItem(StorageKey.UserId);
        Platform.storage().removeItem(StorageKey.IsRealUser);
        Platform.storage().removeItem(StorageKey.AccountType);
        Platform.storage().removeItem(StorageKey.Accounts);
        if (Platform.environment().type() === EnvType.Web) {
            const langCode: LangCode = LanguageUtil.languageCode();
            let url = Platform.reduxState<StoreState>().core.urls.get(UrlType.NewLogin);
            url = url?.replace("{0}", langCode);
            Platform.environment().redirect(WebUtil.addGetParam(url, "language", langCode));
        }
    }

    private fetchPreferences = async (account: LoginAccountInfo): Promise<void> => {
        const authState: AuthState = Platform.state(ServiceType.Auth);
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const serverType: ServerType = accountState.accountType === NXEnvironmentType.Live ? ServerType.TradingReal : ServerType.TradingDemo;
        const origin: string = Platform.config().servers[serverType];
        const preferences: ClientPreference[] = await PreferencesManager.FetchPreferences(origin, authState.token, accountState.accountId, accountState.accountType);
        let ShowAccountDetailsSwiperPreference: boolean = true;
        if (Utils.isArrayNotEmpty(preferences)) {
            preferences.forEach((clientPreference: ClientPreference) => {
                const sKey: string = clientPreference.PreferenceKey;
                if (LSKey.hasKey(sKey) || StorageKey.hasKey(sKey)) {
                    const key: LSKey | StorageKey = clientPreference.PreferenceKey as any;
                    const value: string = clientPreference.PreferenceValue;
                    const preference: Preference<any> = Preference.deserialize(key, value);
                    Platform.dispatch(SetClientPreference({
                        key, preference
                    }));
                    if (key === LSKey.Theme) {
                        Platform.dispatch(SetTheme({themeType: ThemeType.deserialize(value)}));
                    }
                    if (key === StorageKey.InboxTab) {
                        Platform.dispatch(SetNotificationTab({tab: NotificationTab.deserialize(value)}));
                    }
                    if (key === StorageKey.SignalsSettings) {
                        Platform.dispatch(UpdateSignalsSettings({settings: preference.value}));
                    }
                    if (key === StorageKey.ShowFinancialInfoPanel) {
                        ShowAccountDetailsSwiperPreference = preference.value;
                    }
                }
            });
        }
        const {app} = Platform.reduxState<StoreState>();
        if (app.banner?.type) {
            ShowAccountDetailsSwiperPreference = false;
        }
        Platform.dispatch(ToggleAccountDetailsSwiper({value: ShowAccountDetailsSwiperPreference}));
        Platform.dispatch(SetPreferencesFetched({}));
        return Promise.resolve();
    }

    public handleLoginResponse = async (payload: HandleLoginResponsePayload): Promise<void> => {
        const {username, response} = payload;
        const {Accounts, UserId, IsRealAccount} = response;
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const authState: AuthState = Platform.state(ServiceType.Auth);
        authState.token = response.Token;
        accountState.username = username;
        accountState.userId = UserId;
        Platform.storage().setItem(LSKey.Token, response.Token);
        Platform.storage().setItem(StorageKey.UserId, UserId.toString());
        Platform.storage().setItem(StorageKey.IsRealUser, `${Utils.nullTo(IsRealAccount, true)}`);
        if (Utils.isArrayNotEmpty(Accounts)) {
            Accounts.forEach((ac: ResponseAccount) => {
                authState.addAccount({
                    ProfitTradingGroupId: ac.Account.H,
                    BaseAssetId: ac.Account.A,
                    PlatformType: ac.Account.E,
                    EnvironmentType: ac.Account.B,
                    AccountType: ac.Account.I,
                    RelatedAccountType: ac.Account.J,
                    Id: ac.Account.C,
                    RelatedAccountId: ac.Account.K,
                    BaseAssetName: ac.BaseAssetName
                });
            });
            authState.serializeAccounts();
        }
        const realAccount: LoginAccountInfo = authState.getAccount(NXEnvironmentType.Live);
        const demoAccount: LoginAccountInfo = authState.getAccount(NXEnvironmentType.Demo);
        const hasRealAccount: boolean = Utils.isNotNull(realAccount);
        const hasDemoAccount: boolean = Utils.isNotNull(demoAccount);
        const accountType: NXEnvironmentType = hasRealAccount ? NXEnvironmentType.Live : NXEnvironmentType.Demo;
        const account: LoginAccountInfo = authState.getAccount(accountType);
        this._logger.info("Handle Login response for account: " +  + accountType + " Has demo: " + hasDemoAccount + " Has real: " + hasRealAccount
            + " RelatedAccountId: ", account.RelatedAccountId + " RelatedAccountType " + account.RelatedAccountType + " Token: " + response.Token);
        Platform.dispatch(SetAuthStatus({
            authStatus: {
                connected: true,
                hasRealAccount,
                hasDemoAccount,
                hasRelatedAccount: Utils.greaterThen0(account.RelatedAccountId),
            }
        }));
        Platform.dispatch(SetAccountInfo({
            accountInfo: {
                username,
                userId: UserId,
                Currency: account.BaseAssetName
            }
        }));
        BIUtil.SessionStart(
            UserId,
            IsRealAccount,
            BILoginType.Regular,
            "Mobile"
        );
        if (Utils.isEmpty(response.Token)) {
            const answer: [HttpReject, boolean] = await Utils.to(this.doLoginAccountRequest(accountType));
            if (answer[1]) {
                SessionInactivity.start();
                this.nativeGetAfterLoginConfiguration().catch(() => {});
                return Promise.resolve();
            } else {
                return Promise.reject(new Error());
            }
        } else {
            Platform.storage().setItem(StorageKey.AccountType, accountType.toString());
            accountState.accountId = account.Id;
            accountState.accountType = accountType;
            Platform.dispatch(SetAuthStatus({
                authStatus: {
                    authenticated: true,
                    accountType,
                    relatedAccountType: account.RelatedAccountType,
                    loginAccountType: account.AccountType,
                    loginAccountAction: TradeUtil.tradingAccountAction(account),
                }
            }));
            SessionInactivity.start();
            this.fetchPreferences(account).catch(() => {});
            this.nativeGetAfterLoginConfiguration().catch(() => {});
            return Promise.resolve();
        }
    }

    public doLoginAccountRequest = async (accountType: NXEnvironmentType): Promise<boolean> => {
        const authState: AuthState = Platform.state(ServiceType.Auth);
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const account: LoginAccountInfo = authState.getAccount(accountType);
        if (Utils.isNotNull(account)) {
            this._logger.debug("Login to account: " + NXEnvironmentType[accountType]);
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const request: LoginToAccountRequest = {
                EnvironmentType: accountType,
                AccountId: account.Id
            };
            const answer: [HttpReject, LoginToAccountResponse] = await Utils.to(this.sendToLoginSecure(request, "AccountLogin"));
            Platform.dispatch(HideLoader({}));
            if (answer[0]) {
                this._logger.info("Failed to send login to account request. Status: " + answer[0].status);
                XhrUtil.notifyReject(answer[0]);
                return Promise.reject(new Error());
            } else {
                const response: LoginToAccountResponse = answer[1];
                if (response.Token) {
                    this._logger.info("Successful login to account: " + accountType);
                    authState.token = response.Token;
                    accountState.accountType = accountType;
                    accountState.accountId = account.Id;
                    Platform.storage().setItem(LSKey.Token, response.Token);
                    Platform.storage().setItem(StorageKey.AccountType, accountType.toString());
                    Platform.dispatch(SetAuthStatus({
                        authStatus: {
                            accountType,
                            relatedAccountType: account.RelatedAccountType,
                            loginAccountType: account.AccountType,
                            loginAccountAction: TradeUtil.tradingAccountAction(account),
                            authenticated: true
                        }
                    }));
                    Platform.dispatch(SetAccountInfo({
                        accountInfo: {
                            Currency: account.BaseAssetName
                        }
                    }));
                    this.fetchPreferences(account).catch(() => {
                    });
                    return Promise.resolve(true);
                } else {
                    this._logger.info("Failed login to account: " + accountType + " Error: " + response.ErrorMessage);
                    Platform.dispatch(ShowPopup({
                        popup: {
                            type: PopupType.ERROR,
                            message: {customValue: response.LocalizedErrorMessage || response.ErrorMessage},
                            actions: [{type: PopupActionType.OK}]
                        }
                    }));
                    return Promise.reject(new Error());
                }
            }
        } else {
            this._logger.warn("Can't find account by type: " + accountType);
            return Promise.reject(new Error());
        }
    }

    public doFetchUnmaskingSecuredFieldVerificationCode = async (payload: GetUnmaskingSecuredFieldVerificationCodePayload) => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const request: GetUnmaskingSecuredFieldVerificationRequest = {
            ResendVerificationCode: false,
            SendChannelType: payload.SendChannelType
        };
        const answer: [HttpReject, GetUnmaskingSecuredFieldVerificationResponse] = await Utils.to(this.sendToLoginSecure(request, "GetUnmaskingSecuredFieldVerificationCode"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            const {Success, SentChannelType, AvailableChannels, IsLimitReached} = answer[1];
            if (Success) {
                Platform.dispatch(SetVerificationMethod({method: SentChannelType}));
                Platform.dispatch(SetVerificationMethods({methods: AvailableChannels}));
                Platform.dispatch(SetVerificationIsLimitReached({IsLimitReached}));
                if (payload.action) {
                    payload.action();
                }
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            trKey: TranslationKey.errorGeneral
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        }
    }

    public doGetSecuredData = async (payload: GetSecuredDataPayload) => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const {verificationMethodType} = Platform.reduxState<StoreState>().auth;
        const request: GetSecuredDataRequest = {
            FieldType: verificationMethodType === VerificationMethodType.SMS ? MaskedFieldType.Phone : MaskedFieldType.Email,
            VerificationCode: payload.VerificationCode
        };
        const answer: [HttpReject, GetSecuredDataResponse] = await Utils.to(this.sendToLoginSecure(request, "GetSecuredData"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
            Platform.dispatch(SetVerificationCodeError({
                error: {
                    trKey: TranslationKey.verificationError
                }
            }));
        } else {
            if (answer[1].Data) {
                if (payload.action) {
                    payload.action(answer[1].Data);
                } else {
                    setTimeout(() => {
                        Platform.dispatch(
                            SetTradingModal({
                                tradingModalType: TradingModalType.Verification,
                                info: {
                                    visible: false,
                                },
                            })
                        );
                        Platform.dispatch(
                            SetTradingModal({
                                tradingModalType: TradingModalType.PersonalInfo,
                                info: {
                                    visible: true,
                                    params: {
                                        value: answer[1].Data
                                    }
                                },
                            })
                        );
                    }, 1000);
                }
            } else {
                Platform.dispatch(SetVerificationCodeError({
                    error: {
                        trKey: TranslationKey.verificationError
                    }
                }));
            }
        }
    }

    public doSwitchAccount = async (payload: DoSwitchAccountPayload) => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, boolean] = await Utils.to(this.doLoginAccountRequest(payload.accountType));
        Platform.dispatch(HideLoader({}));
        if (answer[1]) {
            Platform.dispatch(OnAccountChanged({}));
            if (payload.accountType === NXEnvironmentType.Live) {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.INFO,
                        icon: {type: PopupIconType.INFO},
                        title: {
                            trKey: TranslationKey.accountReal
                        },
                        showClose: true,
                        message: {
                            trKey: TranslationKey.popupMessageSwitchToLive,
                        },
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        }
    }

    public doCreateRelatedLead = async ({action, accountType}: DoCreateRelatedLeadPayload) => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, CreateRelatedLeadResponse] = await Utils.to(this.sendToLoginSecure({}, "CreateRelatedLead"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            const {Username} = answer[1];
            Platform.dispatch(SetLoginAccountAction({
                action: TradingAccountAction.Switch
            }));
            if (Utils.isNotNull(Username)) {
                Platform.dispatch(SetRelatedLeadUsername({
                    username: Username
                }));
            }
            if (action) {
                action();
            } else {
                Platform.dispatch(SetTradingModal({
                    tradingModalType: TradingModalType.TradingOpenAccount,
                    info: {
                        visible: false,
                    },
                }));
                Platform.dispatch(SetTradingModal({
                    tradingModalType: TradingModalType.TradingAccountSuccess,
                    info: {
                        visible: true,
                    },
                }));
            }
        }
    }

    public doLoginToAccount = async ({accountType, action}: DoLoginToAccountPayload) => {
        this._logger.debug("Login to account: " + accountType);
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const request: LoginToAccountRequest = {
            EnvironmentType: NXEnvironmentType.Live,
            AccountType: accountType
        };
        const answer: [HttpReject, LoginToAccountResponse] = await Utils.to(this.sendToLoginSecure(request, "AccountLogin"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug("Failed login to account");
            XhrUtil.notifyReject(answer[0]);
        } else  {
            const {Token, NewWebProfitLoginUrl} = answer[1];
            this._logger.debug("Login to account platform URL: " + NewWebProfitLoginUrl, " Token ", Token);
            if (Token && NewWebProfitLoginUrl) {
                if (action) {
                    action(answer[1]);
                }
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            trKey: TranslationKey.errorGeneral
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        }
    }

    public makeLoginResponsePayload = async (token: string): Promise<HandleLoginResponsePayload> => {
        const authState: AuthState = Platform.state(ServiceType.Auth);
        authState.token = token;
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, GetAfterLoginConfigurationResponse] = await Utils.to(this.sendToLoginSecure({}, "GetAfterLoginConfigurationForPlatform"));
        Platform.dispatch(HideLoader({}));
        const response: LoginResponse = {};
        let username: string;
        if (answer[1]) {
            const {UserId, UserName, IsRealAccount, HasRelatedLabel, Accounts, VerificationCodeChannels} = answer[1];
            username = UserName;
            response.UserId = UserId;
            response.Token = token;
            response.IsRealAccount = IsRealAccount;
            response.HasRelatedLabel = HasRelatedLabel;
            response.AvailableChannels = VerificationCodeChannels;
            const accounts: ResponseAccount[] = [];
            if (Utils.isArrayNotEmpty(Accounts)) {
                Accounts.forEach((ac: Account) => {
                    accounts.push({
                        BaseAssetName: ac.BaseAssetName,
                        Account: {
                            A: ac.A,
                            B: ac.B,
                            C: ac.C,
                            E: ac.E,
                            H: ac.H,
                            I: ac.I,
                            J: ac.J,
                            K: ac.K,
                        }
                    });
                });
            }
            response.Accounts = accounts;
        } else {
            this._logger.warn("Failed fetch after login configuration");
        }
        return {
            username,
            response
        };
    }

    public doConfirmLogout = () => {
        Platform.dispatch(ShowPopup({
            popup: {
                type: PopupType.CONFIRM,
                icon: {type: PopupIconType.CONFIRM},
                showClose: true,
                message: {
                    trKey: TranslationKey.logoutConfirmation,
                },
                actions: [{type: PopupActionType.CANCEL, text: {trKey: TranslationKey.yes}, action: () => {
                        Platform.dispatch(DoLogout({}));
                        Platform.dispatch(SetLoginRoute({
                            route: RouteType.Login
                        }));
                    }}, {type: PopupActionType.OK, text: {trKey: TranslationKey.no}}]
            }
        }));
    }

    public doLogout = async ({skipNotifyServer}: DoLogoutPayload) => {
        const {authenticated} = Platform.reduxState<StoreState>().auth;
        this._logger.debug("Do logout authenticated: ", authenticated, " skipNotifyServer: ", skipNotifyServer);
        if (authenticated) {
            const inboxEngine: InboxEngine = Platform.engine<InboxEngine>(ServiceType.Inbox);
            await inboxEngine.doBOChatEnd();
            const onResponse = () => {
                this._logger.debug("Logged out.");
                BIUtil.SessionEnd(skipNotifyServer ? BISessionEndType.Forced : BISessionEndType.Regular);
                Platform.dispatch(HideLoader({}));
                Platform.dispatch(CloseAllPopups({}));
                Platform.dispatch(OnLoggedOut({}));
                SessionInactivity.stop();
                this.clear();
            };
            if (skipNotifyServer) {
                onResponse();
            } else {
                const request: LogoutRequest = {BrandId: Platform.config<Configuration>().brandId};
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                this.sendToLoginSecure(request, "Logout")
                    .then(onResponse)
                    .catch(onResponse);
            }
        } else {
            Platform.dispatch(OnLoggedOut({}));
        }
    }

    public doLogOff = async () => {
        this._logger.debug("Logged off.");
        const inboxEngine: InboxEngine = Platform.engine<InboxEngine>(ServiceType.Inbox);
        await inboxEngine.doBOChatEnd();
        try {
            const request: LogoutRequest = {BrandId: Platform.config<Configuration>().brandId};
            const answer: [HttpReject, any] = await Utils.to(this.sendToLoginSecure(request, "Logout"));
            if (answer[0]) {
                this._logger.debug("Failed logg off.");
            }
        } catch (e) {
            this._logger.debug("Exception logg off.");
        }
        BIUtil.SessionEnd(BISessionEndType.Timeout);
        Platform.dispatch(HideLoader({}));
        Platform.dispatch(CloseAllPopups({}));
        Platform.dispatch(ShowPopup({
            popup: {
                type: PopupType.INFO,
                icon: {type: PopupIconType.INFO},
                showClose: false,
                message: {
                    trKey: TranslationKey.yourSessionExpired,
                },
                actions: [{type: PopupActionType.OK, action: () => {
                        Platform.dispatch(OnLoggedOut({}));
                        this.clear();
                    }}]
            }
        }));
    }

    public doChangePassword = async (payload: DoChangePasswordPayload) => {
        if (Utils.isNotEmpty(payload.oldPassword) && Utils.isNotEmpty(payload.newPassword) && Utils.isNotEmpty(payload.confirmPassword)) {
            if (payload.newPassword === payload.confirmPassword) {
                const request: ChangePasswordRequest = {
                    OldPassword: payload.oldPassword,
                    NewPassword: payload.newPassword
                };
                Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                const answer: [HttpReject, ChangePasswordResponse] = await Utils.to(this.sendToLoginSecure(request, "ChangePassword"));
                Platform.dispatch(HideLoader({}));
                if (answer[0]) {
                    Platform.dispatch(SetAuthStatus({
                        authStatus: {changePasswordError: Translations.text(TranslationKey.errorGeneral)}
                    }));
                } else {
                    const response: ChangePasswordResponse = answer[1];
                    if (response.SuccessStatus) {
                        Platform.dispatch(SetAuthStatus({
                            authStatus: {changePasswordError: null}
                        }));
                        Platform.dispatch(SetTradingModal({
                            tradingModalType: TradingModalType.ChangePassword,
                            info: {
                                visible: false
                            }
                        }));
                        if (payload.onSuccess) {
                            payload.onSuccess();
                        }
                    } else {
                        Platform.dispatch(SetAuthStatus({
                            authStatus: {changePasswordError: response.LocalizedErrorMessage || response.ErrorMessage}
                        }));
                    }
                }
            } else {
                Platform.dispatch(SetAuthStatus({
                    authStatus: {changePasswordError: Translations.text(TranslationKey.errorPasswordsNotMatch)}
                }));
            }
        } else {
            this._logger.warn("Change password details absent.");
            Platform.dispatch(SetAuthStatus({
                authStatus: {changePasswordError: Translations.text(TranslationKey.errorFieldsMandatory)}
            }));
        }
    }

    private sendToLogin = (request: any, path: string): Promise<any> => {
        return Xhr.sendTo(ServerType.Login, request, "loginService.svc/json/" + path);
    }

    private sendToLoginSecure = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendToLogin(request, "loginsecureService.svc/json/" + path);
    }
}
